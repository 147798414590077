<template>
  <section>

    <BrunaForm
      ref="holguraCalbucoRef"
      v-bind:fields="this.holgura_calbuco.fields"
      :card="this.holgura_calbuco.card"
      @change="holguraCalbucoChange"
    ></BrunaForm>
    
    <BrunaForm
      ref="holguraCoronelRef"
      v-bind:fields="this.holgura_coronel.fields"
      :card="this.holgura_coronel.card"
      @change="holguraCoronelChange"
    ></BrunaForm>
    
  </section>
</template>

<script>
import { VueAutosuggest } from "vue-autosuggest";
// import CosechaUrgente from "@/views/clients/camanchaca/components/CosechaUrgente";
// import Screenshot from "@/views/clients/camanchaca/components/Screenshot";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import Vue from "vue";
import {
  BRow,
  BCol,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "@axios";
import useApiServices from "@/services/useApiServices.js";
export default {
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  data() {
    return {
      holgura_calbuco: {
        card: {
          title: "Holgura de Seguridad Calbuco",
          subtitle: "",
          tooltip: "",
        },
        fields: [
          {
            label: "Proteína % (minimo):",
            type: "input",
            // placeholder:"Example!",
            id: "proteina_calbuco",
            rules: "required",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: this.operacionUno,
            show: true,
          },
          {
            label: "Otros % (maxímo):",
            type: "input",
            // placeholder:"Example!",
            id: "otros_calbuco",
            rules: "required",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: this.operacionDos,
            show: true,
          },
          {
            label: "Tolerancia Contrato %:",
            type: "input",
            // placeholder:"Example!",
            id: "tolerancia_calbuco",
            rules: "required",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: this.operacionTres,
            show: true,
          },
        ],
      },
      holgura_coronel: {
        card: {
          title: "Holgura de Seguridad Coronel",
          subtitle: "",
          tooltip: "",
        },
        fields: [
          {
            label: "Proteína % (minimo):",
            type: "input",
            // placeholder:"Example!",
            id: "proteina_coronel",
            rules: "required",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: this.operacionCuatro,
            show: true,
          },
          {
            label: "Otros % (maxímo):",
            type: "input",
            // placeholder:"Example!",
            id: "otros_coronel",
            rules: "required",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: this.operacionCinco,
            show: true,
          },
          {
            label: "Tolerancia Contrato %:",
            type: "input",
            // placeholder:"Example!",
            id: "tolerancia_coronel",
            rules: "required",
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4,
            },
            value: this.operacionSeis,
            show: true,
          },
        ],
      },
    };
  },
  components: {
    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    // CosechaUrgente,
    // Screenshot,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    ToastificationContent,
    vSelect,
    BCardTitle,
    BCardSubTitle,
    VBPopover,
    VBTooltip,
    VueAutosuggest,
    BrunaForm,
  },
  created() {},
  mounted() {},
  props: {
    operacionUno: {
      type: String,
      default: null,
    },
    operacionDos: {
      type: String,
      default: null,
    },
    operacionTres: {
      type: String,
      default: null,
    },
    operacionCuatro: {
      type: String,
      default: null,
    },
    operacionCinco: {
      type: String,
      default: null,
    },
    operacionSeis: {
      type: String,
      default: null,
    },
  },
  methods: {
    formatData() {
      return {
        holgura_calbuco: this.holgura_calbuco.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        holgura_coronel: this.holgura_coronel.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
      };
    },
    /*
    createOperaciones() {
      useApiServices
        .postOperacionesCreate(this.formatData(), this)
        .then((response) => {
          this.$emit("nueva-operacion-agregada");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Carga Exitosa`,
              icon: "UploadIcon",
              variant: "success",
              html: `La operacion se ha guardado exitosamente`,
            },
          });
          // console.log('proteina =>', this.findFieldByName(this.operaciones.fields, 'costo_coronel').value);
          // holgura calbuco
          this.findFieldByName(this.holgura_calbuco.fields, 'proteina_calbuco').value = "";
          this.findFieldByName(this.holgura_calbuco.fields, 'otros_calbuco').value = "";
          this.findFieldByName(this.holgura_calbuco.fields, 'tolerancia_calbuco').value = "";
          // holgura calbuco
          this.findFieldByName(this.holgura_coronel.fields, 'proteina_coronel').value = "";
          this.findFieldByName(this.holgura_coronel.fields, 'otros_coronel').value = "";
          this.findFieldByName(this.holgura_coronel.fields, 'tolerancia_coronel').value = "";
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: `Ha ocurrido un error al guardar la nueva operacion</b>.`,
            },
          });
        });
    },
    */
    findFieldByName(fields, name) {
      return fields.find((field) => field.id == name);
    },
    validateCalbuco() {
      return this.$refs.holguraCalbucoRef.validate();
    },
    validateCoronel() {
      return this.$refs.holguraCoronelRef.validate();
    },
    holguraCalbucoChange(data) {
      //this.$emit("changeCalbuco", data);
    },
    holguraCoronelChange(data) {
      //this.$emit("changeCoronel", data);
    },
    getDataCalbuco()
    {
      return this.holgura_calbuco
    },
    getDataCoronel()
    {
      return this.holgura_coronel
    }  
  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }
  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;
      .list-inline {
        display: block;
        li {
          a {
            padding: 0;
          }
          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
<template>
  <b-table-simple class="table-data" sticky-header>
    <b-thead>
      <b-tr>
        <b-th>SKU</b-th>
        <b-th>Materia Prima</b-th>
        <b-th>Precio (USD)</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <b-tr v-for="(item, index) in precios" v-bind:key="index">
        <b-td>{{ item.sku }}</b-td>
        <b-td>{{ item.materia_prima }}</b-td>
        <b-td>{{ item.precio_usd }}</b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>
<script>
import {
  BRow,
  BCol,
  BTableSimple,
  BTbody,
  BThead,
  BTfoot,
  BTr,
  BTd,
  BTh,
} from "bootstrap-vue";

export default {
  props: {
    precios: Array
  },
  components: {
    BRow,
    BCol,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
  },
};
</script>
<style lang="scss">
.table-data {
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
}
</style>
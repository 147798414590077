<template>
  <section>
        <h3>Operaciones</h3>
        <b-row class="mt-2">
          <b-col>
            <h5>Costo de Blending por Toneladas</h5>
            <b-form-group>
              <b-row>
                <b-col cols="3">
                  <label>Coronel USD/Ton:</label>
                </b-col>
                <b-col>
                  <b-form-input v-model="operaciones_coronel.costo_blendin_ton" disabled id="costo_ton" type="number" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col>
            <h5>Costo de Blending por Toneladas</h5>
            <b-form-group>
              <b-row>
                <b-col cols="3">
                  <label>Calbuco USD/Ton:</label>
                </b-col>
                <b-col>
                  <b-form-input v-model="operaciones_calbuco.costo_blendin_ton" disabled id="costo_ton" type="number" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <h5>Velocidad de Planta</h5>
            <b-form-group>
              <b-row>
                <b-col cols="3">
                  <label>Coronel Ton/hr:</label>
                </b-col>
                <b-col>
                  <b-form-input v-model="operaciones_coronel.velocidad_planta" disabled id="ton_hr" type="number" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col>
            <h5>Velocidad de Planta</h5>
            <b-form-group>
              <b-row>
                <b-col cols="3">
                  <label>Calbuco Ton/hr:</label>
                </b-col>
                <b-col>
                  <b-form-input v-model="operaciones_calbuco.velocidad_planta" disabled id="ton_hr" type="number" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <h5>Horas de Funcionamiento Diarias</h5>
            <b-form-group>
              <b-row>
                <b-col cols="3">
                  <label>Coronel HRS:</label>
                </b-col>
                <b-col>
                  <b-form-input v-model="operaciones_coronel.horas_funcionionamiento_diaria" disabled id="fun_diario" type="number" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col>
            <h5>Horas de Funcionamiento Diarias</h5>
            <b-form-group>
              <b-row>
                <b-col cols="3">
                  <label>Calbuco HRS:</label>
                </b-col>
                <b-col>
                  <b-form-input v-model="operaciones_calbuco.horas_funcionionamiento_diaria" disabled id="fun_diario" type="number" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col>
            <h5>Dias de Funcionamiento Semanal</h5>
            <b-form-group>
              <b-row>
                <b-col cols="3">
                  <label>Coronel Días:</label>
                </b-col>
                <b-col>
                  <b-form-input v-model="operaciones_coronel.dias_funconamiento_semanal" disabled id="dias" type="number" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col>
            <h5>Dias de Funcionamiento Semanal</h5>
            <b-form-group>
              <b-row>
                <b-col cols="3">
                  <label>Calbuco Días:</label>
                </b-col>
                <b-col>
                  <b-form-input v-model="operaciones_calbuco.dias_funconamiento_semanal" disabled id="dias" type="number" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
        <hr class="mt-2" />
        <h3>Holgura de Seguridad</h3>
        <b-row class="mt-2">
          <b-col>
            <b-form-group>
              <b-row>
                <b-col cols="3">
                  <label>Proteina % (minimo):</label>
                </b-col>
                <b-col>
                  <b-form-input disabled id="proteina" v-model="form_holgura.proteina_min" type="number" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-row>
                <b-col cols="3">
                  <label>Otros % (maximo):</label>
                </b-col>
                <b-col>
                  <b-form-input disabled id="otros" v-model="form_holgura.otros_max" type="text" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <b-row>
                <b-col cols="3">
                  <label>Tolerancia Contrato %:</label>
                </b-col>
                <b-col>
                  <b-form-input disabled id="tolerancia" v-model="form_holgura.tolerancia_contrato" type="number" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      <hr class="mt-2" />
      <h3>Matriz de Costos Plantas/Bodega</h3>
      <b-row class="mt-2">
        <b-table-simple class="matriz-planta">
          <b-tr variant="secondary">
            <b-td></b-td>
            <b-th>Panitao</b-th>
            <b-th>Neuling</b-th>
            <b-th>Chacabuco</b-th>
            <b-th>Porvenir</b-th>
            <b-th>Coronel</b-th>
            <b-th>Calbuco</b-th>
          </b-tr>
          <b-tr>
            <b-th variant="secondary">Punitao</b-th>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
          </b-tr>
          <b-tr>
            <b-th variant="secondary">Neuling</b-th>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
          </b-tr>
          <b-tr>
            <b-th variant="secondary">Chacabuco</b-th>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
          </b-tr>
          <b-tr>
            <b-th variant="secondary">Porvenir</b-th>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
          </b-tr>
          <b-tr>
            <b-th variant="secondary">Coronel</b-th>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
          </b-tr>
          <b-tr>
            <b-th variant="secondary">Calbuco</b-th>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
            <b-td></b-td>
          </b-tr>
        </b-table-simple>
      </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BTableSimple,
  BThead,
  BTr,
  BTd,
  BTh,
} from "bootstrap-vue";
export default {
  data() {
    return {
      operaciones_calbuco: {
        costo_blendin_ton: "40",
        velocidad_planta: "10.5",
        horas_funcionionamiento_diaria: "20",
        dias_funconamiento_semanal: "6",
      },
      operaciones_coronel: {
        costo_blendin_ton: "40",
        velocidad_planta: "10.5",
        horas_funcionionamiento_diaria: "20",
        dias_funconamiento_semanal: "6",
      },
      form_holgura: {
        proteina_min: "0.3",
        tolerancia_contrato: "20",
        otros_max: "30%",
      },
    };
  },

  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BTh,
  },
};
</script>

<style lang="scss">
.matriz-planta {
  th,
  td {
    border: 1px solid gray;
  }
}
</style>
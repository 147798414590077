<template>
  <b-row>
    <b-col cols="12">
      <div>
        <form-wizard
          color="#b9cde5"
          :title="null"
          :subtitle="null"
          shape="square"
          finish-button-text="Crear Planificación"
          back-button-text="Atras"
          next-button-text="Siguiente"
          class="mb-3"
          @on-complete="formSubmitted"
        >
          <!-- stock -->
          <tab-content title="Stock" :before-change="beforeStockTabSwitch">
            <b-row>
              <b-col>
                <ag-grid-table
                  :configUrl="stock_config"
                  :dataUrl="stock_data"
                  @gridReady="onGridReadyStock"
                  @selectionChanged="selectionStock"
                  @getRowsLoaded="getRowsLoaded"
                  rowSelection="multiple"
                >
                  <template v-slot:actions>
                    <label for="tags-remove-on-delete">Lotes excluidos</label>
                    <b-form-tags
                      input-id="tags-remove-on-delete"
                      v-model="lotes_excluidos_names"
                      placeholder=""
                      class="mb-2"
                      :disabled="true"
                    />
                  </template>
                </ag-grid-table>
              </b-col>
            </b-row>
          </tab-content>

          <!-- contratos -->
          <tab-content
            title="Contratos"
            :before-change="beforeContratoTabSwitch"
          >
            <b-row>
              <b-col>
                <ag-grid-table
                  :configUrl="contrato_config"
                  :dataUrl="contrato_data"
                  @selectionChanged="selectionContratos"
                  rowSelection="multiple"
                >
                  <template v-slot:actions>
                    <label for="contratos-excluidos">Contratos excluidos</label>
                    <b-form-tags
                      input-id="contratos-excluidos"
                      v-model="contratos_excluidos_names"
                      placeholder=""
                      class="mb-2"
                      :disabled="true"
                    />
                  </template>
                </ag-grid-table>
              </b-col>
            </b-row>
          </tab-content>

          <!-- precios -->
          <tab-content title="Precios" :before-change="beforePrecioTabSwitch">
            <ag-grid-table
              :configUrl="sku_config"
              :dataUrl="sku_data"
              @selectionChanged="selectionPrecios"
              rowSelection="single"
            >
            </ag-grid-table>
          </tab-content>

          <!-- ajustes -->
          <tab-content title="Ajustes" :before-change="beforeAjustesTabSwitch">
            <ag-grid-table
              :debug="true"
              ref="grid-historico-lineas-productivas"
              :configUrl="historicoLineasProductivasConfig"
              :dataUrl="historicoLineasProductivasData"
              :editUrl="historicoLineasProductivasEdit"
            >
            </ag-grid-table>
          </tab-content>

          <!-- linea productiva 
          <tab-content
            title="Lineas Productivas"
            :before-change="beforeLineaProductivaTabSwitch"
          >
            <ag-grid-table
              :configUrl="productivas_config"
              :dataUrl="productivas_data"
              @selectionChanged="selectionLineaProductiva"
              rowSelection="single"
            >
              >
            </ag-grid-table>
          </tab-content>
          -->

          <!-- resumen -->
          <tab-content title="Resumen">
            <b-card no-body>
              <div class="card-header">
                <!-- Title & SubTitle -->
                <div>
                  <b-card-title>Stock</b-card-title>
                </div>
              </div>
              <b-card-body>
                <label for="tags-remove-on-delete">Lotes excluidos</label>
                <b-form-tags
                  input-id="tags-remove-on-delete"
                  v-model="lotes_excluidos_names"
                  placeholder=""
                  class="mb-2"
                  :disabled="true"
                />
              </b-card-body>
            </b-card>

            <b-card no-body>
              <div class="card-header">
                <!-- Title & SubTitle -->
                <div>
                  <b-card-title>Contratos</b-card-title>
                </div>
              </div>
              <b-card-body>
                <label for="contratos-excluidos">Contratos excluidos</label>
                <b-form-tags
                  input-id="contratos-excluidos"
                  v-model="contratos_excluidos_names"
                  placeholder=""
                  class="mb-2"
                  :disabled="true"
                />
              </b-card-body>
            </b-card>

            <ag-grid-table
              :configUrl="sku_config"
              :dataUrl="sku_data"
              @selectionChanged="selectionPrecios"
              rowSelection="single"
            >
            </ag-grid-table>

            <ag-grid-table
              :debug="true"
              ref="grid-historico-lineas-productivas"
              :configUrl="historicoLineasProductivasConfig"
              :dataUrl="historicoLineasProductivasData"
              :editUrl="historicoLineasProductivasEdit"
            >
            </ag-grid-table>
          </tab-content>
        </form-wizard>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import
{
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTags
} from "bootstrap-vue";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

import Stock from "./Stock";
import Contratos from "./Contratos";
import Precios from "./Precios";
import Optimizador from "./Optimizador";
// import json
import Data_precios from "../data/precios.json"
import Data_contratos from "../data/contratos.json"

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import AgGridTable from "@/views/brunacomponents/ag-grid-table/AgGridTable.vue";
import Operaciones from "./Operaciones.vue";
import useApiServices from "@/services/useApiServices.js";

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    AgGridTable,
    Operaciones,
    ToastificationContent,
    Stock,
    Contratos,
    Precios,
    Optimizador,
    BFormTags
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data()
  {
    return {
      operacionKey: 0,
      selected: null,
      precios: Data_precios,
      contratos: Data_contratos,
      contrato_config: useApiServices.ingresoContratosConfig,
      contrato_data: useApiServices.ingresoContratosData,
      sku_config: useApiServices.restriccionesSkuConfig,
      sku_data: useApiServices.restriccionesSkuData,
      stock_config: useApiServices.planificacionStockConfig,
      stock_data: useApiServices.planificacionStockData,
      productivas_config: useApiServices.historicoLineasProductivasConfig,
      productivas_data: useApiServices.historicoLineasProductivasData,


      historicoLineasProductivasConfig: useApiServices.historicoLineasProductivasConfig,
      historicoLineasProductivasData: useApiServices.historicoLineasProductivasData,
      historicoLineasProductivasEdit: useApiServices.historicoLineasProductivasEdit,

      lotes_excluidos: [],
      contratos_excluidos: [],
      lotes_excluidos_names: [],
      contratos_excluidos_names: [],


      formatData: {
        stock_id: null,
        contrato_id: null,
        precio_id: null,
        proteina_cal: null,
        proteina_cor: null,
        otro_cal: null,
        otro_cor: null,
        tolerancia_cal: null,
        tolerancia_cor: null,
        linea_prod_id: null
      }
    };
  },

  methods: {



    getRowsLoaded(gridApi, gridColumnApi, params)
    {



    },

    createOperaciones()
    {
      this.setDataCoronel();
      this.setDataCalbuco();
      useApiServices
        .postPlanificacionCreate(this.formatData, this)
        .then((response) =>
        {
          //this.$emit("nueva-operacion-agregada");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Carga Exitosa`,
              icon: "UploadIcon",
              variant: "success",
              html: `La operacion se ha guardado exitosamente`,
            },
          });
        })
        .catch((error) =>
        {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: `Ha ocurrido un error al guardar la nueva operacion</b>.`,
            },
          });
        });
    },
    save()
    {
      this.createOperaciones();
    },
    onGridReadyStock(gridApi, gridColumnApi, params)
    {
      gridApi.forEachNode(function (node)
      {
        //node.selected(node.data.id === this.formatData.stock_id)
        console.log('acaaa' + node)
      });
    },
    beforeStockTabSwitch()
    {
      return true;
    },
    beforeContratoTabSwitch()
    {
      return true;
    },
    beforePrecioTabSwitch()
    {
      return true;
    },

    beforeAjustesTabSwitch()
    {

      return true;
    },

    selectionStock(rows)
    {

      this.lotes_excluidos = rows
      this.lotes_excluidos_names = rows.map(row => row.name)

    },
    selectionContratos(rows)
    {
      this.contratos_excluidos = rows
      this.contratos_excluidos_names = rows.map(row => row.name)
    },
    selectionPrecios(rows)
    {
      this.formatData.precio_id = rows[0].id;
    },
    selectionLineaProductiva(rows)
    {
      this.formatData.linea_prod_id = rows[0].id;
    },
    setDataCoronel()
    {
      let dataCoronel = this.$refs['ajustesRefTab'].getDataCoronel();
      this.formatData.proteina_cor = dataCoronel.fields[0].value;
      this.formatData.otro_cor = dataCoronel.fields[1].value;
      this.formatData.tolerancia_cor = dataCoronel.fields[2].value;
      //console.log(dataCoronel.fields[0].value);
    },
    setDataCalbuco()
    {
      let dataCalbuco = this.$refs['ajustesRefTab'].getDataCalbuco();
      this.formatData.proteina_cal = dataCalbuco.fields[0].value;
      this.formatData.otro_cal = dataCalbuco.fields[1].value;
      this.formatData.tolerancia_cal = dataCalbuco.fields[2].value;
      //console.log(this.$refs['ajustesRefTab'].getDataCalbuco());
    },

    formSubmitted()
    {
      let data = {
        'lotes_excluidos': this.lotes_excluidos.map(row => row.id),
        'contratos_excluidos': this.contratos_excluidos.map(row => row.id)
      }

      useApiServices
        .postPlanificacionCreate(data)
        .then((response) =>
        {

          console.log(response);

          this.$toast({
            component: ToastificationContent,
            props: {
              text: "Se ha creado con éxito una nueva planificación. Se notificara el estado y progreso de esta.",
              title: "Planificación Creada",
              icon: "EditIcon",
              variant: "success",
            },
          });

        });




    },

    alertNext(text)
    {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: "EditIcon",
          variant: "danger",
        },
      });
    },

  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.mb-5 {
  margin-bottom: 5%;
}
</style>
<template>
    <b-table-simple class="table-data" sticky-header>
      <b-thead>
        <b-tr>
          <b-th>Emisión</b-th>
          <b-th>Mes Emisión</b-th>
          <b-th>Contrato</b-th>
          <b-th>Tons</b-th>
          <b-th>Producto SKU</b-th>
          <b-th>Cliente</b-th>
          <b-th>Destino</b-th>
          <b-th>Embarque Inicio</b-th>
          <b-th>Embarque Fin</b-th>
          <b-th>Especie</b-th>
          <b-th>Proteina (%) MIN</b-th>
          <b-th>Proteina (%) MAX</b-th>
          <b-th>Grasa (%) MAX</b-th>
          <b-th>Humedad (%) MIN</b-th>
          <b-th>Humedad (%) MAX</b-th>
          <b-th>Ceniza (%) MAX</b-th>
          <b-th>Sal (%) MAX</b-th>
          <b-th>Arena (%) MAX</b-th>
          <b-th>FFA MAX</b-th>
          <b-th>TVN MAX</b-th>
          <b-th>Histamina MAX</b-th>
          <b-th>Peróxido MAX</b-th>
          <b-th>Antioxidante</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item, index) in contratos" v-bind:key="index">
          <b-td>{{ item.emision }}</b-td>
          <b-td>{{ item.mes_emision }}</b-td>
          <b-td>{{ item.contrato }}</b-td>
          <b-td>{{ item.tons }}</b-td>
          <b-td>{{ item.producto_sku }}</b-td>
          <b-td>{{ item.cliente }}</b-td>
          <b-td>{{ item.destino }}</b-td>
          <b-td>{{ item.embarque_ini }}</b-td>
          <b-td>{{ item.embarque_fin }}</b-td>
          <b-td>{{ item.especie }}</b-td>
          <b-td>{{ item.proteina_min }}</b-td>
          <b-td>{{ item.proteina_max }}</b-td>
          <b-td>{{ item.grasa_max }}</b-td>
          <b-td>{{ item.humedad_min }}</b-td>
          <b-td>{{ item.humedad_max }}</b-td>
          <b-td>{{ item.ceniza_max }}</b-td>
          <b-td>{{ item.sal_max }}</b-td>
          <b-td>{{ item.arena_max }}</b-td>
          <b-td>{{ item.ffa_max }}</b-td>
          <b-td>{{ item.tvn_max }}</b-td>
          <b-td>{{ item.histamina_max }}</b-td>
          <b-td>{{ item.peroxido_max }}</b-td>
          <b-td>{{ item.antioxidante }}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
</template>
<script>
import {
  BRow,
  BCol,
  BTableSimple,
  BTbody,
  BThead,
  BTfoot,
  BTr,
  BTd,
  BTh,
} from "bootstrap-vue";

export default {
  props: {
    contratos: Array
  },
  components: {
    BRow,
    BCol,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
  },
};
</script>
<style lang="scss">
.table-data {
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
}
</style>
<template>
  <div fluid>
    <b-row class="my-1">
      <b-col sm="2">
        <label>Ultima Fecha Sincronización Stock:</label>
      </b-col>
      <b-col sm="4">
        <b-form-input
          v-model="fecha_sinc"
          placeholder="Fecha Sincronización"
          disabled
        ></b-form-input>
      </b-col>
    </b-row>
    <b-row class="tabla">
      <b-col>
        <b-table-simple class="table-data" striped hover>
          <b-thead>
            <b-tr>
              <b-th>Número Lotes</b-th>
              <b-th>Toneladas Totales</b-th>
              <b-th>Toneladas Sardinas</b-th>
              <b-th>Toneladas Salmones RM</b-th>
              <b-th>Toneladas LP</b-th>
              <b-th>Toneladas HP</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>{{data.lotes}}</b-td>
              <b-td>{{data.ton_totales}}</b-td>
              <b-td>{{data.ton_sardinas}}</b-td>
              <b-td>{{data.ton_salmon}}</b-td>
              <b-td>{{data.ton_lp}}</b-td>
              <b-td>{{data.ton_hp}}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BTableSimple,
  BTbody,
  BThead,
  BTr,
  BTd,
  BTh,
} from "bootstrap-vue";
export default {
  data() {
    return {
      fecha_sinc: "17-11-2021 14:50",
      data: {
        lotes: 3291,
        ton_totales: 95.330,
        ton_sardinas: 25.948,
        ton_salmon: 43.983,
        ton_lp: 22.9837,
        ton_hp: 12.937
      }
    };
  },
  components: {
    BRow,
    BCol,
    BFormInput,
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTd,
    BTh,
  },
};
</script>

<style lang="scss">
.tabla {
  margin-top:2%;
}
</style>